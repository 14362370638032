import React from "react";
import styles from "./LargeIcons.module.scss";

const LargeIcons = ({ icons = [] }) => {
  return (
    <div className={styles["LargeIcons"]}>
      {icons.map((icon, index) => (
        <Icon key={index} {...icon} />
      ))}
    </div>
  );
};

const Icon = ({ href = "", src = "", alt = "" }) => {
  return (
    <a className={styles["LargeIcons__Link"]} href={href}>
      <img className={styles["LargeIcons__Logo"]} src={src} alt={alt} />
    </a>
  );
};

export default LargeIcons;
